import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConsoleTourComponent } from '@netfoundry-ui/feature/console-tour';
import { AccountService, BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import {
    EndpointServiceV2,
    NETWORK_SERVICE,
    NetworkServiceV2,
    ZitiControllerService,
} from '@netfoundry-ui/shared/apiv2';
import {
    AuthorizationService,
    AuthService,
    IamService,
    IdletimeoutService,
    LOGIN_SERVICE,
    LoginService,
} from '@netfoundry-ui/shared/authorization';
import { ClientSyncsService } from '@netfoundry-ui/shared/client-syncs';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    _VERSION,
    AzureSubscription,
    ENVIRONMENT,
    Environment,
    Network,
    NetworkGroup,
    NetworkV2,
    Tenant,
} from '@netfoundry-ui/shared/model';

import {
    ApiService,
    AzureSubscriptionService,
    BrandingModeService,
    ClientService,
    CustomerService,
    ErrorHistoryService,
    FeatureService,
    IdentityPreferencesService,
    INFRASTRUCTURE_URLS,
    LoggerService,
    MenuService,
    NetworkGroupService,
    NetworkService,
    OrganizationPreferencesService,
    RefresherService,
    SessionTimerService,
    TokenService,
    URLS,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ShareComponent } from '@netfoundry-ui/shared/share';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { first, get, defer, delay, head, isEmpty, toLower } from 'lodash';
import moment from 'moment';
import { SurveyComponent } from '@netfoundry-ui/feature/survey';

import $ from 'jquery';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import {SETTINGS_SERVICE, SettingsService, ZITI_DOMAIN_CONTROLLER} from 'ziti-console-lib';
import {SelectNetworkModalComponent} from "@netfoundry-ui/feature/select-network-modal";
import {V2NetworkCreationFormComponent} from "@netfoundry-ui/feature/form/v2-network-creation-form";

const EnjoyHint = get(window, 'EnjoyHint', (p?: any) => {});

// @ts-expect-error: import reference to the ZAC growler instance
const { growler } = window;

// interval for the refresher to be called
const loginCheckTimeout = 30000;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
    /**
     * Template Elements V2
     *
     * I am using this area to organize items I know are required for the new design.
     *
     * We should start cleaning up old and unused stuff maybe Rev 2 post templat change
     */
    tabs = '';
    page = '';
    isFilterable = true;
    isLoggedIn = false;
    isZACView = false;
    showLoader = false;
    area = '';
    currentNavItem: any = { isMOPView: true };
    isNavOpen = false;
    isHeader = true;
    isTimeSearchAvailable = false;
    isNotDialLogs = true;
    closeTimeout;
    @ViewChild(ShareComponent, { static: true }) share: ShareComponent;
    location: Location;
    title = 'NetFoundry Console';
    subtitle = 'Console';
    network = '';
    breadcrumb = '';
    bannerMessage = '';
    bannerClass = '';
    showBanner = false;
    showSupportLink = false;
    bannerButtonText = 'Contact';
    resourceType;
    actionTitle = '';
    showTabs = false;
    networks = [];
    url = '';
    isMenuOpen = false;
    isSelfService = false;
    isSelfServiceTeams = false;
    showQuickStart = false;
    showUserTabs = false;
    showProfileTabs = false;
    tenantLabel = '';
    domain = '';
    brandingIsSet = false;
    isShaded = false;
    noPadding = false;
    networkLimits;
    currentAccount;
    currentNetwork: Network | NetworkV2 = new Network({});
    currentOrg: NetworkGroup = new NetworkGroup({});
    previousNetwork: Network | NetworkV2 = new Network({});
    organizationList = [];
    orgLookup = {};
    hasAzureSubscription = false;
    isFirstLoad = true;
    isFirstAdd = true;
    isFirstPage = false;
    dialogRef;
    confirmDialog;
    idleTimeoutWarningActive = false;
    tenantId;
    _hideSideBar = false;
    shortBreadCrumbs = false;
    isEnterprise = false;
    showBannerSpinner = false;
    isGrowlersOpen = false;
    initRoute = '';
    ignoredRoutes = ['/callback','/login', '/'];
    showExpandCollapseIcon = false;
    private growlerServiceSub: Subscription;
    private currentNetworkSub = new Subscription();
    private azureSubSub = new Subscription();
    private networkServiceSub = new Subscription();
    private subscription = new Subscription();
    private selfLinkSub: Subscription = new Subscription();
    private lastErrorSub = new Subscription();
    private hasNoNetworks = false;
    /* Networking Controls From Header */
    private networkServiceGetSub: Subscription = new Subscription();
    // variable to hold the refresher
    private loginCheckRefresher;
    private gettingStartedWasShown = false;
    private billingInvalid = false;
    private wasPricingObtained = false;
    private werePermissionsObtained = false;
    private wasAzureSubscriptionInitialized = false;
    private currentSubscription: any;
    private currentPaymentProfile: any;
    private currentContract: any;

    constructor(
        private logger: LoggerService,
        private router: Router,
        private refresher: RefresherService,
        @Inject(LOGIN_SERVICE) public login: LoginService,
        private growlService: GrowlerService,
        private accountService: AccountService,
        public apiService: ApiService,
        private networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private changeDetector: ChangeDetectorRef,
        private iamService: IamService,
        public dialogForm: MatDialog,
        private idleTimeout: IdletimeoutService,
        public brandingService: BrandingModeService,
        public featureService: FeatureService,
        public billingEnabledService: BillingEnabledService,
        private titleService: Title,
        private azureSubscriptionService: AzureSubscriptionService,
        private zenDeskService: NgxZendeskWebwidgetService,
        private billingService: BillingService,
        private cookieService: CookieService,
        private zitiEnabledService: ZitiEnabledService,
        public authorizationService: AuthorizationService,
        private menuService: MenuService,
        private organizationService: NetworkGroupService,
        private sortByPipe: SortbyPipe,
        private errorHistoryService: ErrorHistoryService,
        private authService: AuthService,
        private clientSyncService: ClientSyncsService,
        private tokenService: TokenService,
        private clientService: ClientService,
        private sessionTimerService: SessionTimerService,
        private endpointServiceV2: EndpointServiceV2,
        private identityPreferencesService: IdentityPreferencesService,
        private organizationPreferencesService: OrganizationPreferencesService,
        private customerService: CustomerService,
        @Inject(SETTINGS_SERVICE) public settingsService: SettingsService,
        @Inject(ZITI_DOMAIN_CONTROLLER) public zitiControllerService: ZitiControllerService,
        @Inject(ENVIRONMENT) public environment: Environment,
        @Inject(_VERSION) private version: any
    ) {}

    // function for keeping the user from timing out
    @HostListener('click', [])
    @HostListener('document:keydown', [])
    @HostListener('window:scroll', [])
    @HostListener('document:mousemove', [])
    onUserMovement() {
        if (this.isLoggedIn && !this.idleTimeoutWarningActive) {
            // if the user is logged in and the user clicks their mouse, scrolls the page, clicks a key, or moves their mouse
            //  reset the idle timeout
            this.idleTimeout.initIdleTimeout();
        }
    }

    handleMouseOver(event: any) {
      defer(() => {
        this.showExpandCollapseIcon = true;
        if (!this.menuService.locked) {
          this.menuService.resetArea();
        }
      })
    }

    get hideSideBar() {
      const page = this.url.split(';')[0].split('?')[0];
      return this._hideSideBar || page === URLS.INVITATION || page === URLS.SIGNUP
    }

    async ngOnInit() {
        this.initRoute = window?.location?.pathname;
        await this.zenDeskService.initZendesk();
        this.initZacGrowlerHandler();
        const currentUri = window.location?.pathname + window.location?.search;
        this.subscription.add(() => {
            this.apiService.currentUserPreferences.subscribe((userPreferences) => {
                if (isEmpty(userPreferences)) {
                    return;
                }
                const page = this.url.split(';')[0].split('?')[0];
                this.setupSubtitle(page);
            });
        });

        this.subscription.add(
            this.tokenService.logout.subscribe(() => {
                this.growlService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'Session Expired',
                        'Your session has expired, please login again to continue'
                    )
                );
                this.login.logout(false, true);
            })
        );

        this.zenDeskService.zE('webWidget', 'updateSettings', {
            webWidget: { zIndex: 999999 },
        });
        this.zenDeskService.zE('webWidget', 'hide');
        this.isNavOpen = this.menuService.areaId != null && this.menuService.areaId.length > 0;
        this.subscription.add(
            this.menuService.area.subscribe((area) => {
                this.area = area;
                this.isNavOpen = area.length > 0;
                this.handleCloudZitiAssets();
                if (this.isZACView) {
                  this.checkForZitiSession();
                }
            })
        );
        this.subscription.add(
            this.menuService.navItemChanged.subscribe((navItem) => {
                this.currentNavItem = navItem;
                this.handleCloudZitiAssets();
            })
        );
        if (this.environment['faviconUrl'] != null) {
            this.setFavicon(this.environment['faviconUrl']);
        }
        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                localStorage.setItem('tenantId', tenant.id);
                this.tenantId = tenant.id;
                this.tenantLabel = tenant.label;
                const html = document.getElementsByTagName('html')[0];

                this.brandingService.fetchBrandCss().then((rawCss) => {
                    html.style.cssText = rawCss;
                    this.brandingIsSet = true;
                });

                if (this.featureService.isNewSelfServiceWorkflow(this.tenantLabel)) {
                    this.showGettingStarted();
                }

                if (this.tenantId === this.environment.pldtId) {
                    this.idleTimeout.setTimeoutInterval(this.environment.pldtTimeoutSeconds);
                }
            })
        );

        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = org;
            })
        );

        this.subscription.add(
            this.accountService.currentAccount.subscribe((account) => {
                this.currentAccount = account;
                this.isEnterprise = account?.billingMode === 'Enterprise';
                this.isSelfService = account?.billingMode === 'SelfService';
                if (!isEmpty(account.id)) {
                    this.billingService
                        .getContractsByAccountId(account.id)
                        .toPromise()
                        .then((contracts: any) => {
                            this.currentContract = head(contracts);
                            this.apiService.setCurrentContract(this.currentContract);
                        });
                }
            })
        );

        this.subscription.add(
            this.apiService.currentSubscription.subscribe((subscription) => {
                this.currentSubscription = subscription;
                this.subscriptionChanged(subscription);
            })
        );

        this.subscription.add(
            this.apiService.currentPaymentProfile.subscribe((paymentProfile) => {
                this.currentPaymentProfile = paymentProfile;
                this.paymentProfileChanged(paymentProfile);
            })
        );

        this.subscription.add(
            this.billingEnabledService.isBillingEnabled.subscribe((billingEnabled) => {
                this.setBillingEnabled(billingEnabled);
            })
        );

        this.login.handleVanityUrl();
        this.login.handleAuthenticationWithHash();

        this.router.events.subscribe((event) => {
            defer(() => {
                this.navigationChanged(event);
            });
        });
        // setting a check to validate the user is logged in to run every 30 seconds
        this.loginCheckRefresher = setInterval(() => {
            // checking if the user was previously logged in. if they were not, it doesn't make sense to try to log them out
            if (this.isLoggedIn) {
                // checking whether or not the user is logged in
                this.isLoggedIn = this.login.isAuthenticated();
                // if the auth check fails, logging the user out
                if (!this.isLoggedIn) {
                    this.logger.info('Session token is no longer valid, logging out');
                    this.login.logout(false);
                    this.zenDeskService.zE('webWidget', 'hide');
                }
            }
        }, loginCheckTimeout);
        // Watch the current network
        this.currentNetworkSub.add(
            this.apiService.currentNetwork.subscribe((network) => {
                if (isEmpty(network?.id)) {
                    this.setTempOrganization();
                    return;
                }
                this.previousNetwork = network;
                if (this.breadcrumb === 'Dashboard') {
                    if (network.name) {
                        this.titleService.setTitle('NetFoundry: ' + network.name);
                    } else {
                        this.titleService.setTitle('NetFoundry Console');
                    }
                }

                // this.currentNetworkName = network.name;
                let organizationId;
                const version = this.apiService.getNetworkVersion(network);
                if (version < 7) {
                    this.currentNetwork = new Network(network);
                    organizationId = this.currentNetwork.getLinkedResourceId('organization');
                } else {
                    this.currentNetwork = network as unknown as NetworkV2;
                    organizationId = this.currentNetwork.networkGroupId;
                }
                // @FIX ME - we should clean this up, but the feature service doesn't work quite right if we remove it -MG
                if (this.currentOrg.id == null || this.currentOrg.id !== organizationId) {
                    this.logger.info('attempting to set current org');
                    if (organizationId != null) {
                        this.setOrganization(organizationId);
                    } else {
                        this.setTempOrganization();
                    }
                }
                if (this.router.url === URLS.HOME || this.router.url === URLS.OLD_HOME) {
                    this.subtitle = this.currentNetwork.name !== undefined ? this.currentNetwork.name : 'Console';
                    this.titleService.setTitle('NetFoundry: ' + this.subtitle);
                }
                this.initNetworkLimits();
                this.handleBannerMessageVisibility();
            })
        );
        this.lastErrorSub.add(
            this.apiService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && (typeof error.error === 'string' || error.error instanceof String)) {
                    const errorObject = JSON.parse(String(error.error));
                    if (errorObject && errorObject[0] && errorObject[0]['message']) {
                        errorMessage = errorObject[0]['message'];
                    } else if (errorObject && errorObject.message) {
                        errorMessage = errorObject.message;
                    }
                } else if (error.error && error.error[0] && error.error[0]['message']) {
                    errorMessage = error.error[0]['message'];
                } else if (error.error && error.error.detail) {
                    errorMessage = error.error.detail;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', errorMessage));
            })
        );
        this.lastErrorSub.add(
            this.iamService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && error.error.message) {
                    errorMessage = error.message;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', errorMessage));
            })
        );
        this.lastErrorSub.add(
            this.authService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && error.error.message) {
                    errorMessage = error.message;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', error.message));
            })
        );
        this.lastErrorSub.add(
            this.clientSyncService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && error.error.message) {
                    errorMessage = error.message;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', error.message));
            })
        );

        // subscribing to the timeout warning alert. when this subscription is triggered and the alert is true
        //  then the user should be warned that they are about to be logged out due to being idle
        this.subscription.add(
            this.idleTimeout.timeoutWarningAlert.subscribe((timeoutAlert) => {
                if (timeoutAlert === true) {
                    this.idleTimeoutWarningActive = true;
                    let interval;
                    let unit;
                    if (this.environment.defaultTimeoutWarningSeconds < 121) {
                        unit = 'seconds';
                        interval = this.environment.defaultTimeoutWarningSeconds;
                    } else {
                        unit = 'minutes';
                        interval = Math.floor(this.environment.defaultSessionTimeoutSeconds / 60);
                    }
                    const data = {
                        title: 'Your Session is About to Timeout',
                        appendId: 'AppComponent',
                        subtitle: `Are you still there? You have ${interval} ${unit} before being signed off. Would you like to stay signed in?`,
                        icon: 'Timeout',
                        action: 'Yes Please',
                        cancelAction: 'No, sign me out',
                        isTimeoutWarning: true,
                    };
                    this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
                        data: data,
                        height: '340px',
                        width: '600px',
                        autoFocus: false,
                    });
                    this.confirmDialog.beforeClosed().subscribe((result) => {
                        // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                        //  logged out of the console and we should close the dialog without continuing
                        if (result === undefined) {
                            this.idleTimeout.initIdleTimeout();
                            this.idleTimeoutWarningActive = false;
                        } else if (result['loggingOut'] === undefined) {
                            if (result) {
                                this.idleTimeout.initIdleTimeout();
                                this.idleTimeoutWarningActive = false;
                            } else {
                                this.idleTimeout.clearIdleTimeout();
                                this.login.logout(false);
                                this.zenDeskService.zE('webWidget', 'hide');
                            }
                        }
                    });
                }
            })
        );
        this.subscription.add(
          this.settingsService.settingsChange.subscribe((settings) => {
            this.checkForZitiSession();
          })
        );
        $('.cdk-overlay-container').addClass('mopView');
    }

    navigationChanged(event) {
        if (event instanceof NavigationStart) {
            clearTimeout(this.refresher.refreshTimerId);
        }
        if (event instanceof NavigationEnd) {
            if ((<any>window).gtag) {
                (<any>window).gtag('set', 'page', event.urlAfterRedirects);
                (<any>window).gtag('send', 'pageview');
            }
            const page = event.url.split(';')[0].split('?')[0];
            this.page = page;
            const newIsLoggedIn = this.login.isAuthenticated();
            // checking if the user is actually logged in
            if (this.isLoggedIn && !newIsLoggedIn) {
                // if the user's token is no longer valid, logging them out
                this.logger.info('Session token is no longer valid, logging out: ', event.url);
                this.isLoggedIn = false;
                // clearing the timeout before logging out
                this.subtitle = 'Login';
                this.idleTimeout.clearIdleTimeout();
                this.login.logout(false);
                this.zenDeskService.zE('webWidget', 'hide');
                this.werePermissionsObtained = false;
                this.wasAzureSubscriptionInitialized = false;
            } else if (
                page === URLS.SELFSERVICE ||
                page === URLS.SIGNUP ||
                page === URLS.LOGIN ||
                page === URLS.OLD_LOGIN
            ) {
                // the user will be logged out by going to any of these pages and we don't want to redirect them
                // from their intended destination if they navigate to the singup or self service page.
                // Marking isLoggedIn as false and preventing the getOrganization call from being made
                this.isLoggedIn = false;
                // clearing the timeout before logging out
                this.idleTimeout.clearIdleTimeout();
            } else {
                if (this.authorizationService.canListTenants() && this.apiService.theTenantIs.id == null) {
                    this.iamService.get('tenants', localStorage.getItem('tenantId')).subscribe((tenant) => {
                        this.apiService.setCurrentTenant(new Tenant(tenant));
                    });
                }
                if (!this.isLoggedIn && newIsLoggedIn) {
                  if (
                    !this.authorizationService.canListNetworks() &&
                    !this.authorizationService.canListOrganizations() &&
                    !this.authorizationService.canListTenants()
                  ) {
                    if (this.authorizationService.canViewBillingSubscriptions() || this.authorizationService.canViewPaymentProfile()) {
                      this.apiService.currentUser.subscribe((user: any) => {
                        this.accountService.getAccountDetailsForTenantId(user?.tenantId).then((account: any) => {
                          this.isEnterprise = account.billingMode === 'Enterprise';
                          this.billingEnabledService.checkBilling().then(() => {
                            this.menuService.setArea('Billing');
                            this.openBilling();
                          })
                        })
                      });
                    } else {
                      // User has not serviceable role for the console
                      this.logger.error('User has not serviceable role for the console. Check assigned roles on user entities page.')
                    }
                  } else if (
                    this.authorizationService.canListTenants() &&
                    !this.authorizationService.canListNetworks() &&
                    !this.authorizationService.canListOrganizations()
                  ) {
                    // if the user can list tenants, don't navigate to the dashboard since they can't view it
                    this.router.navigate([URLS.USERS]);
                  } else {
                    this.showLoader = true;
                    this._hideSideBar = false;
                    this.shortBreadCrumbs = false;
                    let identityId = null;
                    const iamServiceProm = this.iamService
                      .get('identities', 'self')
                      .pipe(take(1))
                      .toPromise()
                      .then((identity) => {
                        identityId = identity['id'];
                        this.apiService.setCurrentUser(identity);
                      });

                    this.organizationService
                      .get()
                      .pipe(take(1))
                      .toPromise()
                      .then((orgs) => {
                        this.orgLookup = {};
                        this.organizationList = this.sortByPipe.transform(orgs, 'createdAt', 'asc');
                        if (this.authorizationService.canListNetworks()) {
                          this.loadNetworks();
                        } else if (page === URLS.HOME || page === URLS.OLD_HOME || page === '/') {
                          if (this.tenantId) {
                            this.router.navigate([URLS.USERS]);
                          } else {
                            this.router.navigate([URLS.LOGS]);
                          }
                          this.showLoader = false;
                        } else {
                          this.showLoader = false;
                        }
                        for (const org of this.organizationList) {
                          this.orgLookup[org.id] = org as NetworkGroup[];
                        }

                        this.organizationPreferencesService.getPreferences().then((result) => {
                          this.logger.info('preferences', result);
                          if (!result && this.tenantId) {
                            this.organizationPreferencesService
                              .createOrgPreference(this.tenantId)
                              .subscribe((result) => result);
                            this.organizationPreferencesService.getPreferences();
                          }
                        });
                      });
                    // if the user is logged in, kick off the idle timeout service
                    this.idleTimeout.initIdleTimeout();
                    // ensuring that the token renewal is scheduled
                    this.login.scheduleRenewal();
                  }
                }
                this.isLoggedIn = newIsLoggedIn;
                if (!this.brandingIsSet) {
                    // make sure we have org info before setting the branding
                    const html = document.getElementsByTagName('html')[0];

                    if (!this.tenantLabel) {
                        this.tenantLabel = localStorage.getItem('tenantLabel');
                    }
                    this.brandingService.fetchBrandCss(this.tenantLabel).then((rawCss) => {
                        html.style.cssText = rawCss;
                        this.brandingIsSet = true;
                    });
                }
            }
            this.url = event.url;
            this.isMenuOpen = false;
            this.showTabs = false;
            this.showUserTabs = false;
            this.showProfileTabs = false;
            this.setupSubtitle(page);
        }
    }

    ngOnDestroy() {
        if (this.growlerServiceSub) this.growlerServiceSub.unsubscribe();
        if (this.currentNetworkSub) this.currentNetworkSub.unsubscribe();
        if (this.networkServiceSub) this.networkServiceSub.unsubscribe();
        if (this.selfLinkSub) this.selfLinkSub.unsubscribe();
        if (this.lastErrorSub) this.lastErrorSub.unsubscribe();
        if (this.azureSubSub) this.azureSubSub.unsubscribe();
        if (this.networkServiceGetSub) this.networkServiceGetSub.unsubscribe();
        // clearing the idle timeout at the end of the component lifecycle
        this.idleTimeout.clearIdleTimeout();
        // clearing the interval on the login check refresher
        clearInterval(this.loginCheckRefresher);
    }

    bannerButtonClicked() {
        switch (this.bannerClass) {
            case 'unsupported':
                this.openSupport();
                break;
            case 'overdue':
                this.openBilling();
                break;
            case 'suspended':
                this.resumeNetwork();
                break;
        }
    }

    openBilling() {
        if (this.isEnterprise) {
            this.router.navigate(['/payment-profile']);
        } else {
            this.router.navigate(['/billing']);
        }
    }

    openSupport() {
        window.open('http://support.netfoundry.io');
    }

    setSuspendedBannerWarning() {
        const network: any = this.currentNetwork;
        if (this.isSelfServiceTeams) {
            const updatedDate = moment(network?.updatedAt);
            const today = moment();
            let daysLeft = 15 - Math.abs(updatedDate.diff(today, 'days'));
            if (daysLeft < 0) {
                daysLeft = 0;
            }
            this.bannerMessage = `This network has had no utilization for >7 days and has been moved to stopped state. The network will be deleted if there is no utilization in ${daysLeft} days`;
        } else {
            this.bannerMessage = `This network has been moved to stopped state since there was no utilization for 7 days. To continue service, please contact cust-support@netfoundry.io.`;
        }
        this.bannerClass = 'suspended';
        this.showBanner = true;
        this.showSupportLink =
            this.authorizationService.canUpdateNetwork(network?.id, network?.networkGroupId) &&
            !this.authorizationService.isSupportUser();
        this.bannerButtonText = 'Resume Network';
    }

    resumeNetwork() {
        let chargeWarning = '';
        if (!this.isSelfServiceTeams) {
            chargeWarning = ' Charges may apply depending on your subscription.';
        }
        const data = {
            title: 'Resume Network',
            appendId: 'AppComponentResume',
            subtitle: `Would you like to resume activity on this network?${chargeWarning}`,
            action: 'Yes Please',
            cancelAction: 'No, get me out of here',
            isTimeoutWarning: false,
        };
        this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '200px',
            width: '600px',
            autoFocus: false,
        });
        this.confirmDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.showBannerSpinner = true;
                this.bannerMessage = `Network resuming. Please wait a moment...`;
                this.showSupportLink = false;
                this.customerService
                    .selfResume()
                    .then(() => {
                        this.growlService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                `Network Resume In Progress`,
                                `Your request to resume the network was successful. Please wait a moment for the update to complete.`
                            )
                        );
                    })
                    .catch((error) => {
                        this.showBannerSpinner = false;
                        this.setSuspendedBannerWarning();
                        this.growlService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                `Network Resume Failed`,
                                `Your request to resume the network failed. Please contact support is the issue persists.`
                            )
                        );
                    });
            }
        });
    }

    close() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
        // this.logger.info('msst');
        if (!this.menuService.locked && this.menuService.isOpen()) {
            // this.logger.info('not lockt');
            this.closeTimeout = setTimeout(() => {
                // this.logger.info('my timeout');
                this.menuService.setArea('');
            }, 2000);
        }
    }

    showGettingStarted() {
        const showSurvey = localStorage.getItem('showSurvey_' + this.tenantLabel) === 'true';
        if (showSurvey) {
            this.dialogRef = this.dialogForm.open(SurveyComponent, {
                data: {},
                height: '455px',
                width: '935px',
                autoFocus: false,
            });
            localStorage.removeItem('showSurvey_' + this.tenantLabel);
            this.menuService.setArea('');
            this.dialogRef.afterClosed().subscribe(() => {
                if (this.currentNetwork?.status === 'PROVISIONED') {
                    this.dialogRef = this.dialogForm.open(ConsoleTourComponent, {
                        data: {},
                        height: '455px',
                        width: '935px',
                        autoFocus: false,
                    });
                    this.dialogRef.afterClosed().subscribe(() => {
                        localStorage.setItem(`take-console-tour-complete_${this.tenantLabel}`, 'true');
                    });
                }
            });
        }
        this.gettingStartedWasShown = true;

        if (!this.billingInvalid) {
            this.router.navigate([URLS.GETTING_STARTED]);
        }

        localStorage.removeItem('showSurvey_' + this.tenantLabel);
        const netGroupId = get(this.organizationList, '[0].id');
        if (netGroupId && this.tenantLabel) {
            const createNewNetwork = localStorage.getItem('createNetwork_' + this.tenantLabel) === 'true';
            this.hasNoNetworks = !this.networks?.length || this.networks?.length === 0;
            if (createNewNetwork && this.hasNoNetworks) {
                // Console is no longer driving network creation
                //this.createNewNetwork();
                localStorage.removeItem('createNetwork_' + this.tenantLabel);
            }
        }
    }

    // Loads all networks
    public async loadNetworks() {
        await this._loadV7Networks();

        // if there are any networks
        if (this.networks.length > 0) {
            // check initial load
            if (this.isFirstLoad) {
                // handle some app initialization logic and check and see if we have anything stored in cookies
                this.initDefaultApp();
                this._initialNetworkLoad(this.networks);
                // we have already loaded the initial app context, just refresh the data and make sure nothing has changed
            } else {
                let wasCurrentNetFound = false;
                for (const network of this.networks) {
                    const networkModel = this.apiService.getNetworkModel(network);
                    if (this.currentNetwork.id === networkModel.id) {
                        wasCurrentNetFound = true;
                        // set the fresh copy of the model just in case the status has changed
                        this.apiService.setCurrentNetwork(networkModel);
                        break;
                    }
                }
                // if we didn't find the network model in the list then it was probably deleted, go find the first one in the list
                if (!wasCurrentNetFound) {
                    this.apiService.setCurrentNetwork(this.networks[0]);
                }
            }
            // there are no networks in the list!
        } else {
            if (this.isFirstAdd) {
                this.isFirstAdd = false;
                // set an empty v6 network as a default
                this.apiService.setCurrentNetwork(new Network({}));
            }
        }
    }

    ngAfterViewChecked() {
        (<any>window).SetupRippler();
    }

    closeWarning() {
        this.billingEnabledService.closeWarning();
    }

    async setBillingEnabled(isBillingEnabled) {
        if (isBillingEnabled) {
            this.billingEnabledService.openWarning();
        } else {
            this.billingEnabledService.closeWarning();
        }
        if (isBillingEnabled && this.authorizationService.canListComponentTypes()) {
            this.wasPricingObtained = true;
            this.billingService.initPricingData();
        }
    }

    async checkOverdueStatus(subscriptionId, isSelfService = false) {
        this.billingService.getOverdueStatus(subscriptionId, isSelfService).then((result) => {
            if (result.isOverdue && result.daysOverdue >= 1 && result.daysOverdue < 45) {
                this.bannerMessage = `Invoice for the month of <b>${result.monthAndYear}</b> is due for payment. Reach us at <a href="mailto:collection@netfoundry.io">collection@netfoundry.io</a> if you have paid the invoice already.`;
                this.showBanner = true;
                this.bannerClass = 'overdue';
                this.showSupportLink = false;
                this.bannerButtonText = '';
            } else if (result.isOverdue && result.daysOverdue >= 45) {
                this.bannerMessage = `Invoice for the month of <b>${result.monthAndYear}</b> is overdue for payment. Please complete payment to avoid suspension of services. Reach us at <a href="mailto:collection@netfoundry.io">collection@netfoundry.io</a> if you have paid the invoice already.`;
                this.showBanner = true;
                this.bannerClass = 'overdue';
                this.showSupportLink = false;
                this.bannerButtonText = '';
            }
        });
    }

    getActiveSubscription(subscriptions: any) {
        if (isEmpty(subscriptions)) {
            return head(subscriptions);
        }
        let activeSub;
        try {
            for (let i = 0; i < subscriptions.length; i++) {
                const sub = subscriptions[i];
                if (toLower(sub?.status) !== 'cancelled') {
                    activeSub = sub;
                    break;
                }
            }
        } catch (err) {
            activeSub = head(subscriptions);
        }
        return activeSub;
    }

    setTempOrganization() {
        const tmpOrganizationId = get(this.organizationList, '[0].id');
        this.setOrganization(tmpOrganizationId);
    }

    private handleBannerMessageVisibility() {
        const network: any = this.currentNetwork;
        const netVersion = network?.productVersion?.charAt(0);
        const vetVersionNum = Number.parseInt(netVersion, 10);
        if (vetVersionNum === 6) {
            this.bannerMessage = `TERMINATION OF SUPPORT NOTICE: V6 is no longer supported, you are using this
                          version of our product at your own risk, click
                          <a href="https://support.netfoundry.io/hc/en-us/articles/
                          5168208798349-Notice-of-Discontinuance-and-Termination-of-Order-Form-upon-Expiration-Date"
                          target="_blank" class="banner-link">here</a> for further information.`;
            this.showBanner = true;
            this.showSupportLink = false;
            this.bannerClass = 'unsupported';
        } else if ((network as unknown as NetworkV2).status === 'SUSPENDED') {
            this.setSuspendedBannerWarning();
        } else {
            const clearBanner = this.bannerClass === 'unsupported' || this.bannerClass === 'suspended';
            if (clearBanner) {
                this.bannerMessage = '';
                this.showBanner = false;
                this.showSupportLink = false;
                this.bannerClass = '';
            }
        }
    }

    private highlightQuickStartButton() {
        const showHint = localStorage.getItem('nf_show_quick_start_hint_' + this.tenantLabel) === 'true';
        if (!showHint || !this.featureService.showGettingStarted) {
            return;
        }
        const enjoy_hint_options = {
            showSkip: true,
            showNext: true,
            showPrev: true,
            btnSkipText: 'Got it',
            radius: 20,
            onEnd: () => {},
            keyCode: '',
            top: 100,
            onSkip: () => {},
        };
        const enjoyhint_instance = new EnjoyHint(enjoy_hint_options);
        const hints = [
            {
                event: 'quick-start-badge',
                selector: '.QuickStart',
                //arrowColor: 'transparent',
                margin: 0,
                shape: 'circle',
                skipButton: { className: 'quickStartSkip', text: 'Got it' },
                description:
                    '<h2>Here\'s a hint</h2><p>Use the <text style="color: #4fcbff">Quick Start</text> button at any time to return to the <text style="color: #4fcbff">Getting Started</text> page. <p>There you\'ll find links to our documentation and tutorials</p>',
                onBeforeStart: () => {},
            },
        ];
        delay(() => {
            enjoyhint_instance.set(hints);
            enjoyhint_instance.run();
        }, 100);
        localStorage.removeItem('nf_show_quick_start_hint_' + this.tenantLabel);
    }

    private setupSubtitle(url: string) {
        this.isHeader = true;
        this.actionTitle = '';
        this.isShaded = false;
        this.isTimeSearchAvailable = false;
        this.resourceType = undefined;
        this.noPadding = false;
        switch (url) {
            case URLS.BROWZER: {
                this.subtitle = 'BrowZer Apps';
                this.actionTitle = 'Add a BrowZer App';
                this.breadcrumb = this.subtitle;
                this.resourceType = 'browzer-app';
                this.isFilterable = true;
                this.tabs = 'browzer';
                break;
            }
            case URLS.BROWZER_BOOTSTRAPPERS: {
                this.subtitle = 'BrowZer Bootstrappers';
                this.actionTitle = 'Add a BrowZer Bootstrapper';
                this.breadcrumb = this.subtitle;
                this.resourceType = 'browzer-bootsrapper';
                this.isFilterable = true;
                this.tabs = 'browzer';
                break;
            }
            case URLS.ZITIROUTERS:
            case URLS.BROWZER_ROUTERS:
          case INFRASTRUCTURE_URLS.MANAGED_ROUTERS:
            case INFRASTRUCTURE_URLS.ROUTERS: {
                this.subtitle = 'Edge Routers';
                this.actionTitle = 'Add An Edge Routers';
                this.breadcrumb = this.subtitle;
                this.resourceType = 'edge-router';
                this.isFilterable = true;
                this.tabs = 'controllers';
                break;
            }
            case URLS.BILLING: {
                this.subtitle = 'Billing Dashboard';
                this.breadcrumb = 'Billing Dashboard';
                this.tabs = 'billing';
                this.isShaded = true;
                this.isFilterable = false;
                break;
            }
            case URLS.BILLING_DASHBOARD: {
                this.subtitle = 'Billing Dashboard';
                this.breadcrumb = 'Billing Dashboard';
                this.tabs = 'app-billing';
                this.isShaded = true;
                this.isFilterable = false;
                break;
            }
            case URLS.ORG_BILLING: {
              this.subtitle = 'Billing Dashboard';
              this.breadcrumb = 'Billing Dashboard';
              this.tabs = 'org-billing';
              this.isShaded = true;
              this.isFilterable = false;
              break;
            }
            case URLS.SUPPORT_BILLING: {
              this.subtitle = 'Billing Dashboard';
              this.breadcrumb = 'Billing Dashboard';
              this.tabs = 'support-billing';
              this.isShaded = true;
              this.isFilterable = false;
              break;
            }
            case URLS.OLD_LOGS:
            case URLS.LOGS: {
                this.subtitle = 'Organization Events';
                this.breadcrumb = 'Organization Events';
                this.tabs = 'orgLogs';
                this.actionTitle = 'Export';
                this.isFilterable = true;
                this.isTimeSearchAvailable = true;
                break;
            }
            case URLS.OLD_OLD_SETTINGS:
            case URLS.OLD_SETTINGS:
            case URLS.SETTINGS: {
                this.subtitle = 'Organization Settings';
                this.breadcrumb = 'Organization Settings';
                this.tabs = 'organization';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.OLD_SUBSCRIPTIONS:
            case URLS.SUBSCRIPTIONS: {
                this.subtitle = 'Endpoint Lifecycle';
                this.breadcrumb = 'Endpoint Lifecycle';
                this.tabs = 'integrations';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.CLIENT_SYNC_AAD: {
                this.subtitle = 'Azure Active Directory';
                this.breadcrumb = 'Manage AAD';
                this.tabs = 'integrations';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.CLIENT_SYNC_AD: {
                this.subtitle = 'Active Directory';
                this.breadcrumb = 'Manage AD';
                this.tabs = 'integrations';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.IDENTITIES_BASE:
            case URLS.OLD_OLD_USERS:
            case URLS.OLD_USERS:
            case URLS.USER_ROLES:
            case URLS.USERS: {
                this.subtitle = 'Users';
                this.breadcrumb = this.subtitle;
                this.actionTitle = 'Add A User';
                this.tabs = 'users';
                this.isFilterable = true;
                break;
            }
            case URLS.USER: {
                this.subtitle = 'User Admin';
                this.breadcrumb = this.subtitle;
                this.actionTitle = '';
                this.tabs = 'user';
                this.isFilterable = true;
                break;
            }
            case URLS.OLD_DAILYSTATS:
            case URLS.OLD_RESOURCE_USAGE:
            case URLS.RESOURCE_USAGE:
            case URLS.DAILY_ENDPOINT_UTILIZATION:
            case URLS.DAILYSTATS: {
                this.subtitle = 'Reporting and Analytics';
                this.breadcrumb = this.subtitle;
                this.actionTitle = 'Download CSV';
                this.tabs = 'reporting';
                this.isFilterable = false;
                this.isShaded = false;
                break;
            }
            case URLS.OLD_NETWORK_GROUP_SUMMARY:
            case URLS.NETWORK_GROUP_SUMMARY: {
                this.subtitle = 'Reporting and Analytics';
                this.breadcrumb = this.subtitle;
                this.tabs = 'reporting';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.BILLING_SUBSCRIPTIONS: {
                this.subtitle = 'Billing Subscriptions';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.APP_BILLING_SUBSCRIPTIONS: {
                this.subtitle = 'Billing Subscriptions';
                this.breadcrumb = this.subtitle;
                this.tabs = 'app-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.ORG_BILLING_SUBSCRIPTIONS: {
                this.subtitle = 'Billing Subscriptions';
                this.breadcrumb = this.subtitle;
                this.tabs = 'org-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.SUPPORT_BILLING_SUBSCRIPTIONS: {
                this.subtitle = 'Billing Subscriptions';
                this.breadcrumb = this.subtitle;
                this.tabs = 'support-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.PAYMENT_PROFILE: {
                this.subtitle = 'Billing Management';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.BILLING_PAYMENT_PROFILE: {
                this.subtitle = 'Billing Management';
                this.breadcrumb = this.subtitle;
                this.tabs = 'app-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.ORG_PAYMENT_PROFILE:
                this.subtitle = 'Billing Management';
                this.breadcrumb = this.subtitle;
                this.tabs = 'org-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            case URLS.SUPPORT_PAYMENT_PROFILE:
                this.subtitle = 'Billing Management';
                this.breadcrumb = this.subtitle;
                this.tabs = 'support-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            case URLS.ACCOUNT_DETAILS: {
                this.subtitle = 'Account Details';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.BILLING_ACCOUNT_DETAILS: {
                this.subtitle = 'Account Details';
                this.breadcrumb = this.subtitle;
                this.tabs = 'app-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.ORG_ACCOUNT_DETAILS: {
                this.subtitle = 'Account Details';
                this.breadcrumb = this.subtitle;
                this.tabs = 'org-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.SUPPORT_ACCOUNT_DETAILS: {
                this.subtitle = 'Account Details';
                this.breadcrumb = this.subtitle;
                this.tabs = 'support-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.BILLING_SUBSCRIPTION_OVERAGES: {
                this.subtitle = 'Billing Subscription Overages';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.APP_BILLING_SUBSCRIPTION_OVERAGES: {
                this.subtitle = 'Billing Subscription Overages';
                this.breadcrumb = this.subtitle;
                this.tabs = 'app-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.ORG_BILLING_SUBSCRIPTION_OVERAGES: {
                this.subtitle = 'Billing Subscription Overages';
                this.breadcrumb = this.subtitle;
                this.tabs = 'org-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.SUPPORT_BILLING_SUBSCRIPTION_OVERAGES: {
                this.subtitle = 'Billing Subscription Overages';
                this.breadcrumb = this.subtitle;
                this.tabs = 'support-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.BILLING_SUBSCRIPTION_USAGE: {
                this.subtitle = 'Billing Subscription Usage';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.APP_BILLING_SUBSCRIPTION_USAGE: {
                this.subtitle = 'Billing Subscription Usage';
                this.breadcrumb = this.subtitle;
                this.tabs = 'app-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.ORG_BILLING_SUBSCRIPTION_USAGE: {
                this.subtitle = 'Billing Subscription Usage';
                this.breadcrumb = this.subtitle;
                this.tabs = 'org-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.SUPPORT_BILLING_SUBSCRIPTION_USAGE: {
                this.subtitle = 'Billing Subscription Usage';
                this.breadcrumb = this.subtitle;
                this.tabs = 'support-billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.OLD_MANAGE_INVITATIONS:
            case URLS.MANAGE_INVITATIONS: {
                this.subtitle = 'Invitations';
                this.breadcrumb = this.subtitle;
                this.actionTitle = 'Add A Invitations';
                this.tabs = 'users';
                this.isFilterable = true;
                break;
            }
            case URLS.ROLES: {
                this.subtitle = 'Roles & Permissions';
                this.actionTitle = 'Add A Role';
                this.breadcrumb = 'Roles';
                this.isFilterable = true;
                this.tabs = 'roles';
                break;
            }
            case URLS.STORED_SECRETS: {
                this.subtitle = 'Stored Secrets';
                this.actionTitle = 'Add A Stored Secret';
                this.breadcrumb = 'Stored Secrets';
                this.isFilterable = true;
                this.tabs = 'stored_secrets';
                break;
            }
            case URLS.OLD_TOKENS:
            case URLS.TOKENS: {
                this.subtitle = 'Api Accounts';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.actionTitle = 'Add Api';
                this.tabs = 'tokens';
                break;
            }
            case URLS.OLD_PROFILE:
            case URLS.PROFILE: {
                this.subtitle = 'Profile';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                this.isShaded = true;
                this.tabs = 'profile';
                break;
            }
            case URLS.USER_SETTINGS:
            case URLS.OLD_BRANDING:
            case URLS.BRANDING: {
                this.subtitle = 'User Settings';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                this.isShaded = true;
                this.tabs = 'profile';
                break;
            }
            case URLS.NOTIFICATIONS: {
                this.subtitle = 'manage Notifications';
                this.actionTitle = 'Add A Notification';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'events';
                break;
            }
            case INFRASTRUCTURE_URLS.NETWORK_GROUPS:
            case URLS.NETWORK_GROUPS: {
                this.subtitle = 'Network Groups';
                this.actionTitle = 'Add A Network Group';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'network-groups';
                break;
            }
            case INFRASTRUCTURE_URLS.NETWORKS:
            case URLS.OLD_NETWORK:
            case URLS.NETWORKS_COMBINED:
            case URLS.NETWORK: {
                this.subtitle = 'Networks';
                this.actionTitle = 'Add A Network';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'networks';
                break;
            }
            case URLS.NETWORK_CONTROLLERS: {
                this.subtitle = 'Network Controllers';
                this.actionTitle = 'Add A Network Controller';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'controllers';
                break;
            }
            case URLS.ALLOCATED_IPS: {
                this.subtitle = 'Allocated Ips';
                this.actionTitle = 'Add An Allocated Ip';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'controllers';
                break;
            }
            case URLS.PROCESS_EXECUTIONS: {
                this.subtitle = 'Network Processes';
                this.actionTitle = 'View and manage processes for the current network';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                this.showTabs = true;
                this.tabs = 'processes';
                if (this.featureService.disableNewNavigation) {
                    this.tabs = 'networks';
                } else {
                    this.tabs = 'processes';
                }
                break;
            }
            case URLS.OLD_ORGANIZATIONS:
            case URLS.ORGANIZATIONS: {
                this.subtitle = 'Organizations';
                this.actionTitle = 'Add An Organization';
                this.breadcrumb = 'Organizations';
                this.isFilterable = true;
                this.tabs = 'organizations';
                break;
            }
            case URLS.PASSWORD: {
                this.subtitle = 'Password';
                this.breadcrumb = 'User Preferences <span class="slash">/</span> ' + this.subtitle;
                this.isFilterable = false;
                this.showProfileTabs = true;
                this.showTabs = true;
                break;
            }
            case URLS.V7EVENTS:
            case URLS.OLD_EVENTS:
            case URLS.MANAGEMENT_EVENTS: {
                this.subtitle = 'Management Events';
                this.breadcrumb = 'Management Events';
                this.isFilterable = true;
                this.actionTitle = 'Export';
                this.tabs = 'events';
                this.isTimeSearchAvailable = true;
                this.isNotDialLogs = true;
                break;
            }
            case URLS.NETWORK_EVENTS: {
                this.subtitle = 'Network Events';
                this.breadcrumb = 'Network Events';
                this.isFilterable = true;
                this.actionTitle = 'Export';
                this.tabs = 'events';
                this.isTimeSearchAvailable = true;
                this.isNotDialLogs = true;
                break;
            }
            case URLS.OLD_ALARMS:
            case URLS.ALARMS: {
                this.subtitle = 'Network Alarms';
                this.breadcrumb = 'Network Alarms';
                this.isFilterable = true;
                this.actionTitle = 'Export';
                this.tabs = 'events';
                this.isTimeSearchAvailable = true;
                break;
            }
            case URLS.OLD_LOGIN:
            case URLS.LOGIN: {
                this.subtitle = 'Login';
                this.isLoggedIn = false;
                this.isFilterable = false;
                this.showTabs = false;
                this.showBanner = false;
                this.showQuickStart = false;
                break;
            }
            case URLS.PAGENOTFOUND:
            case URLS.SIGNUP:
            case URLS.SELFSERVICE: {
                this.subtitle = 'Sign Up';
                this.isLoggedIn = false;
                this.isFilterable = false;
                this.showTabs = false;
                break;
            }
            case URLS.ERRORS: {
                this.subtitle = 'Errors';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                break;
            }
            case URLS.ZITI_CERTIFICATE_AUTHORITIES: {
                this.subtitle = 'Certificate Authorities';
                this.breadcrumb = 'Certificate Authorities';
                this.isShaded = false;
                this.isFilterable = true;
                this.actionTitle = 'Add A CA';
                this.tabs = 'ca';
                break;
            }
            case INFRASTRUCTURE_URLS.METRICS:
            case URLS.METRICS: {
                this.subtitle = 'Metrics';
                this.breadcrumb = 'Metrics';
                this.isShaded = false;
                this.isFilterable = false;
                this.showTabs = false;
                this.tabs = '';
                break;
            }
            case URLS.SAMPLE_SERVICE:
            case URLS.GETTING_STARTED: {
                this.isShaded = true;
                this.noPadding = true;
                this.isFilterable = false;
                this.showTabs = false;
                this.tabs = '';
                this.isHeader = false;
                break;
            }
            default: {
                if (this.currentNetwork.name !== null && this.currentNetwork.name !== undefined) {
                    this.subtitle = this.currentNetwork.name;
                } else {
                    this.subtitle = 'Dashboard';
                }
                this.isHeader = false;
                this.isFilterable = false;
                this.breadcrumb = 'Dashboard';
                this.showTabs = false;
                break;
            }
        }
        if (this.subtitle !== undefined) {
            this.titleService.setTitle('Cloud Ziti: ' + this.subtitle);
        } else {
            this.titleService.setTitle('Cloud Ziti Console');
        }
        if (this.isLoggedIn) {
            this.zenDeskService.zE('webWidget', 'helpCenter:setSuggestions', { search: this.subtitle });
            this.zenDeskService.zE('webWidget', 'identify', {
                name: localStorage.getItem('profile_nick'),
                email: localStorage.getItem('profile_email'),
            });
            if (!this.isFirstPage) {
                this.zenDeskService.zE('webWidget', 'hide');
                this.isFirstPage = true;
            }
        } else {
            this.zenDeskService.zE('webWidget', 'hide');
        }
    }

    private handleCloudZitiAssets() {
        this.isZACView =
          (this.area === 'ZAC' && !this.currentNavItem.isMOPView) || this.currentNavItem.isZACView || (this.area === '' && this.menuService.lastArea === 'ZAC' && !this.currentNavItem.isMOPView);
    }

    private setFavicon(faviconUrl) {
        if (document != null && document.getElementById('appFavicon') != null) {
            document.getElementById('appFavicon').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple57').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple60').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple72').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple76').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple114').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple120').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple144').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple152').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple180').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon192').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon32').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon96').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon16').setAttribute('href', faviconUrl);
        }
    }

    /**
     * Set the OrgID for the app
     */
    private async setOrganization(orgId) {
        // making sure the user is logged in before attempting to set the org
        if (this.isLoggedIn && orgId !== this.currentOrg.id) {
            const org = this.orgLookup[orgId];
            if (orgId != null && org != null) {
                localStorage.setItem('organizationId', orgId);
                this.logger.info('Setting ORG', org);
                this.currentOrg = new NetworkGroup(org);
                this.apiService.setCurrentOrg(this.currentOrg);
                this.featureService.checkFeatures(orgId);
                if (this.authorizationService.canListAzureSubscriptions()) {
                    this.getAzureSubscription(this.currentOrg);
                }
            } else {
                this.logger.info('OrgID is empty!');
            }
        } else {
            return;
        }
    }

    private async subscriptionChanged(subscription) {
        if (subscription?.subscriptionId) {
            this.checkOverdueStatus(subscription.subscriptionId, this.isSelfService);
        }
        if (!this.isSelfService) {
            return;
        }
        if (this.featureService.showGettingStarted && !this.gettingStartedWasShown) {
            this.showGettingStarted();
        }
        this.isSelfServiceTeams = this.featureService.isSelfServiceTeamsTier(subscription);
        const isNewInvitedUser = localStorage.getItem('newInvitedUser_' + this.tenantLabel) === 'true';
        if (isNewInvitedUser && this.isSelfService) {
            this.customerService.setNewSelfServiceUserFlags(this.tenantLabel);
        }
        if (!isEmpty(this.currentPaymentProfile)) {
            this.checkPaymentMethodStatus(this.currentPaymentProfile);
        }
        this.handleBannerMessageVisibility();
    }

    private async paymentProfileChanged(paymentProfile) {
        if (this.isSelfService && !isEmpty(this.currentSubscription)) {
            this.checkPaymentMethodStatus(paymentProfile);
        }
    }

    private checkPaymentMethodStatus(paymentProfile) {
        if (
            !this.authorizationService.isSupportUser() &&
            !this.isEnterprise &&
            !this.isSelfServiceTeams &&
            paymentProfile?.paymentMethod?.status === 'INVALID'
        ) {
            this.apiService.setBillingStatusInvalid(true);
            this.billingInvalid = true;
            this.router.navigate([URLS.BILLING]);
        }
    }

    private getAzureSubscription(currentOrg: NetworkGroup) {
        this.apiService
            .getLinkedResource(currentOrg, 'azureSubscription')
            .toPromise()
            .then((result) => {
                this.wasAzureSubscriptionInitialized = true;
                this.hasAzureSubscription = false;
                if (
                    result['_embedded'] &&
                    result['_embedded']['azureSubscriptions'] &&
                    result['_embedded']['azureSubscriptions'].length > 0
                ) {
                    const azureSubscriptions = result['_embedded']['azureSubscriptions'];
                    this.hasAzureSubscription = true;
                    this.logger.info('Set Azure Subscription', azureSubscriptions[0]);
                    this.apiService.setCurrentAzureSubscription(new AzureSubscription(azureSubscriptions[0]));
                } else {
                    this.apiService.setCurrentAzureSubscription(new AzureSubscription({}));
                }
            });
    }

    private initNetworkLimits() {
        if (isEmpty(this.currentNetwork?.id)) {
            return;
        }
        this.networkServiceV2
            .getNetworkLimits(this.currentNetwork.id)
            .toPromise()
            .then((networkLimits: any) => {
                this.networkLimits = networkLimits?._embedded?.networkLimitList || [];
                const currentLimit = first(this.networkLimits);
                this.networkServiceV2.setCurrentNetworkLimit(currentLimit);
            });
    }

    private _initialNetworkLoad(combined) {
        this.isFirstLoad = false;
        let cookieSelfLink = null;
        let cookieNetwork = null;
        let network;
        // check if we have a network saved in cookies
        if (this.cookieService.get('currentNetwork')) {
            cookieSelfLink = this.cookieService.get('currentNetwork');
            // this.logger.info('COOKIE LINK', cookieSelfLink);
            cookieNetwork = combined.find((netwrk) => netwrk._links.self.href === cookieSelfLink);
        }
        // if we have a selfLink saved in cookies and we found a matching network, load it up
        if (cookieSelfLink && cookieNetwork) {
            this.logger.info('Load network from cookie');
            network = this.apiService.getNetworkModel(cookieNetwork);
            // else we didn't find any saved network in the cookies
        } else {
            this.logger.info('No cookie network found, load first network that is not in error');
            const ignoredNetworkStates = [500, 'ERROR', 'DELETING', 'DELETED'];
            let networkToSelect = combined.find((nw) => !ignoredNetworkStates.includes(nw.status));
            if (!networkToSelect) {
                networkToSelect = combined[0];
            }
            network = this.apiService.getNetworkModel(networkToSelect);
        }
        const currentNetworkId = this.apiService.getInitialNetworkId();
        const preferedNetworkId = this.identityPreferencesService.getPreference('default_network_id');
        let networkId;
        if (currentNetworkId) {
          networkId = currentNetworkId;
        } else {
          networkId = preferedNetworkId;
        }
        if (networkId) {
          let network;
          this.networks.forEach((nw) => {
            if (nw?.id === networkId) {
              network = nw;
            }
          });
          if (network) {
            this.apiService.setCurrentNetwork(network);
            return;
          }
        } else if (this.networks.length === 1) {
          this.currentNetwork = this.networks[0];
          this.apiService.setCurrentNetwork(this.currentNetwork);
          return;
        } else if (this.networks.length === 0) {
          return;
        }
        this.dialogRef = this.dialogForm.open(SelectNetworkModalComponent, {
          data: {
            networks: combined
          },
          autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result?.id) {
              this.apiService.setCurrentNetwork(result);
              if (result.status === 'PROVISIONING') {
                this.router.navigate([URLS.PROCESS_EXECUTIONS]);
              }
            } else if (result?.newNetwork) {
              const network: any = new Network({});
              network.name = 'Select a Network';
              network.isPlaceholder = true;
              this.apiService.setCurrentNetwork(network);
              this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
              });
            } else {
              const network: any = new Network({});
              network.name = 'Select a Network';
              network.isPlaceholder = true;
              this.apiService.setCurrentNetwork(network);
              this.router.navigate([URLS.NETWORKS_COMBINED]);
            }
        });
    }

    private initDefaultApp() {
      const doAppInit = this.ignoredRoutes.includes(this.initRoute);
      if (!doAppInit) {
        return;
      }
      this.apiService.resetInitialNetworkId();
      const defaultApp = this.identityPreferencesService.getPreference('default_app_id');
      if (defaultApp) {
        this.menuService.setApp(defaultApp);
        let route = '/dashboard';
        switch (defaultApp) {
          case 'networks':
            route = INFRASTRUCTURE_URLS.DASHBOARD;
            break;
          case 'infrastructure':
            route = INFRASTRUCTURE_URLS.DASHBOARD;
            break;
          case 'organization':
            route = '/user-identities/users';
            break;
          case 'billing':
            route = '/billing';
            break;
          case 'browzer':
            route = '/browzer-apps';
            break;
        }
        this.router.navigate([route]);
      }
    }

    private async _loadV7Networks() {
        return await this.networkServiceV2.getNetworks({ pageParams: { size: 2000, page: 0 }, params: { embed: 'network-controllers' } }).then(
            (networks: any) => {
                const zitiNetworks = [];
                for (const zitiNetwork of networks) {
                    // only show v2 networks that are in a living state
                    if (zitiNetwork.status !== 'DELETING' && zitiNetwork.status !== 'DELETED') {
                        zitiNetworks.push(zitiNetwork);
                    }
                }
                this.networks = zitiNetworks;
                delay(() => {
                  this.networkServiceV2._networks.next(this.networks);
                }, 1000);
            }, // @TODO - better handling - 404's are ERROR but OK if no one has V2 networks yet
            (error) => {
                this.logger.error('Error returning V2 networks for app-component', error);
                this.networks = [];
            }
        );
    }

    private checkForZitiSession(attempts = 0) {
      attempts++;
      delay(() => {
          if (this.isZACView && !this.zitiControllerService.hasZitiSession && this.currentNetwork?.id) {
            if (attempts > 4) {
              this.bannerMessage = 'Waiting to connect to ziti controller...';
              this.showBanner = true;
            }
            this.checkForZitiSession(attempts);
          } else {
            this.showBanner = false;
          }
      }, 2000);
    }

    private initZacGrowlerHandler() {
        if (growler) {
            const growlerError = growler?.error;
            growler.error = (message) => {
                if (this.zitiControllerService.hasZitiSession) {
                  growlerError(message);
                }
            };
        }
    }
}
