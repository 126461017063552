<div class="er-links-container" [ngClass]="{'router-loading': isLoading}">
  <div class="er-links-buttons">
    <div (click)="copyToken()" class="download-button" *ngIf="showRegistrationKey">
      <div class="download-key"></div>
      <div>COPY REGISTRATION KEY</div>
      <div class="tap-to-copy"></div>
    </div>
    <div (click)="svc.downloadJWT()" *ngIf="hasJwt && !enrollmentExpired" class="download-button">
      <div class="download-jwt"></div>
      <div>DOWNLOAD ENROLLMENT JWT</div>
      <div class="tap-to-download"></div>
    </div>
    <div (click)="reissueEnrollmentJWT()" *ngIf="showReissueJWT" class="download-button" >
      <div class="refresh-jwt icon-refresh"></div>
      <div>REISSUE ENROLLMENT JWT</div>
      <div class="tap-to-download"></div>
    </div>
    <div (click)="reEnrollRouter()" class="download-button" *ngIf="showReEnroll">
      <div class="download-key"></div>
      <div>Re-Enroll Router</div>
      <div class="tap-to-download"></div>
    </div>
    <!--<div (click)="downloadConfig()" class="download-button download-config">
      <div class="download-icon router-icon"></div>
      <div>DOWNLOAD CONFIG</div>
      <div class="download-icon config-icon"></div>
    </div>-->
    <div (click)="getVMs()" class="download-button download-vm" >
      <div class="download-key vm-icon"></div>
      <div>GET THE VM</div>
      <div class="vm-download"></div>
    </div>
    <span class="info-text" *ngIf="enrollmentExpired">ENROLLMENT JWT EXPIRED {{expirationDate}}</span>
  </div>
</div>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>
