/*
    Copyright NetFoundry Inc.

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/

import {Component, Inject, OnInit} from '@angular/core';
import {EdgeRouterServiceV2} from "@netfoundry-ui/shared/apiv2";
import {EDGE_ROUTER_EXTENSION_SERVICE, GrowlerModel, GrowlerService, EdgeRoutersPageService, ZITI_DATA_SERVICE, ZitiDataService} from 'ziti-console-lib';
import {EdgeRoutersExtensionService} from "../edge-routers-extension/edge-routers-extension.service";
import {ApiService} from "@netfoundry-ui/shared/services";

import {isEmpty} from 'lodash';
import moment from 'moment';

@Component({
  selector: 'netfoundry-ui-edge-router-links',
  templateUrl: './edge-router-links.component.html',
  styleUrls: ['./edge-router-links.component.scss'],
})
export class EdgeRouterLinksComponent implements OnInit {

  currentNetwork: any;
  zitiRouterData: any;
  isLoading = false;

  constructor(
    private edgeRouterService: EdgeRouterServiceV2,
    private apiService: ApiService,
    private growlerService: GrowlerService,
    public edgeRoutersSvc: EdgeRoutersPageService,
    @Inject(EDGE_ROUTER_EXTENSION_SERVICE) public svc: EdgeRoutersExtensionService,
    @Inject(ZITI_DATA_SERVICE) private zitiService: ZitiDataService,
  )
  {

  }

  ngOnInit() {
    this.apiService.currentNetwork.subscribe((network) => {
      this.currentNetwork = network;
    });
  }

  copyToken() {
    this.svc.copyRegistration();
  }

  reEnrollRouter() {
    this.svc.reEnrollRouter();
  }

  reissueEnrollmentJWT() {
    this.isLoading = true;
    return this.zitiService.post(`edge-routers/${this.svc?.zitiRouterData?.id}/re-enroll`, {}, true).then((result) => {
      const growlerData = new GrowlerModel(
        'success',
        'Success',
        `Re-enrollment Confirmed`,
        `A new enrollment JWT is now available`,
      );
      this.growlerService.show(growlerData);
      this.svc.getZitiRouterData().finally(() => {
        this.isLoading = false;
      });
    }).catch(() => {
      const growlerData = new GrowlerModel(
        'error',
        'Error',
        `Re-enroll Failed`,
        `Failed to reset the enrollment JWT`,
      );
      this.growlerService.show(growlerData);
      this.isLoading = false;
    });
  }

  get showRegistrationKey() {
    return isEmpty(this.svc?.nfRouterData?.id);
  }

  get showReEnroll() {
    return (this.svc?.zitiRouterData?.isVerified && this.svc.zitiRouterData.hostname.indexOf('netfoundry.io') < 0);
  }

  get enrollmentExpired() {
    return (this.svc?.zitiRouterData?.enrollmentExpiresAt && moment(this.svc?.zitiRouterData?.enrollmentExpiresAt).isBefore());
  }

  get expirationDate() {
    return moment(this.svc?.zitiRouterData?.enrollmentExpiresAt).local().format('M/D/YY h:mm a');
  }

  downloadConfig() {
    //TODO once API is available
  }

  get hasJwt() {
    return !isEmpty(this.svc.zitiRouterData?.enrollmentJwt)
  }

  get showReissueJWT() {
    return !this.svc?.zitiRouterData?.isVerified && this.enrollmentExpired;
  }

  getVMs() {
    window.open('https://netfoundry.io/downloads/#zitirouters', '_blank');
  }
}
