import { Injectable, EventEmitter, Inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService, ExtensionService } from '@netfoundry-ui/shared/services';
import { EdgeRouterServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';
import { EdgeRouterV2 } from '@netfoundry-ui/shared/model';
import {
  EDGE_ROUTER_EXTENSION_SERVICE,
  GrowlerModel,
  GrowlerService,
  ZITI_DATA_SERVICE,
  ZitiDataService
} from 'ziti-console-lib';

import { get, isEmpty, unset, delay, merge, cloneDeep } from 'lodash';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { MatDialog } from '@angular/material/dialog';
import { GrowlerData } from '@netfoundry-ui/shared/growler';
import { RouterHostingConfigComponent } from '../router-hosting-config/router-hosting-config.component';
import {EventsModalComponent} from "@netfoundry-ui/feature/events-modal";
import {MetricsModalComponent} from "@netfoundry-ui/feature/metrics-modal";
import {Router} from "@angular/router";
import {TableHeaderDefaultComponent} from "@netfoundry-ui/feature/data-table";

@Injectable({ providedIn: 'root' })
export class EdgeRoutersExtensionService implements ExtensionService {
    _DEFAULT_NF_ROUTER_DATA = {
      provider: 'AWS',
      locationCode: undefined,
      linkListener: true,
      wssListener: false,
      alternateDomainName: '',
    }
    nfHosted: any = false;
    zitiRouterData: any = {};
    nfRouterData: any = this.DEFAULT_NF_ROUTER_DATA;
    newRouter = false;
    hosting = false;
    errors: any = {};
    zitiRouterChanged: BehaviorSubject<any> = new BehaviorSubject<any>(this.zitiRouterData);
    formDataChanged = new BehaviorSubject<any>({ isEmpty: true });
    closed: EventEmitter<any> = new EventEmitter<any>();
    currentNetwork: any = {};
    dialogRef: any;
    hideZitiRegistration = true;
    nfHostToggled = false;
    loadingZitiRouterId = '';
    moreActions = [
      {name: 'Show Events', action: 'show-events', label: 'Show Events', callback: this.showEventsModal.bind(this), hidden: this.hideEventsAction.bind(this)},
      {name: 'Show Metrics', action: 'show-metrics', label: 'Show Metrics', callback: this.showMetricsModal.bind(this), hidden: this.hideMetricsAction.bind(this)}
    ]

    listActions = [
      {name: 'Show Events', action: 'show-events', label: 'Show Events', callback: this.showEventsModalFromListPage.bind(this)},
      {name: 'Show Metrics', action: 'show-metrics', label: 'Show Metrics', callback: this.showMetricsModalFromListPage.bind(this)},
      {name: 'Re-Enroll', action: 're-enroll', label: 'Re-Enroll', callback: this.reEnrollRouter.bind(this), isHidden: this.hideReEnroll.bind(this)},
      {name: 'Copy Registration', action: 'download-enrollment', label: 'Copy Registration', callback: this.copyRegistration.bind(this), isHidden: this.hideCopyRegistration.bind(this)}
    ]

    hiddenColumns = [
      'jwt',
      'enrollmentToken'
    ];

    columnExtensions = []

    constructor(
        private edgeRouterService: EdgeRouterServiceV2,
        private apiService: ApiService,
        private growlerService: GrowlerService,
        private dialogForm: MatDialog,
        private router: Router,
        @Inject(ZITI_DATA_SERVICE) private zitiService: ZitiDataService
    ) {
        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;
        });
    }

    get DEFAULT_NF_ROUTER_DATA() {
      return cloneDeep(this._DEFAULT_NF_ROUTER_DATA);
    }

    resetNFRouterData() {
      return this.nfRouterData = this.DEFAULT_NF_ROUTER_DATA;
    }

    hideEventsAction() {
      return isEmpty(this.zitiRouterData?.id);
    }

    hideMetricsAction() {
      return isEmpty(this.zitiRouterData?.id);
    }

    async formDataSaved(data: any): Promise<any> {
        const isUpdate = !isEmpty(this.zitiRouterData.id);
        const growlerData = new GrowlerModel(
            'success',
            'Success',
            `Edge Router ${isUpdate ? 'Updated' : 'Created'}`,
            `Successfully ${isUpdate ? 'updated' : 'created'} Edge Router: ${this.zitiRouterData.name}`
        );
        this.growlerService.show(growlerData);
        if (!isUpdate) {
            this.newRouter = true;
            this.nfHosted = true;
        }

        this.zitiRouterData.id = data?.id || this.zitiRouterData.id;
        this.nfRouterData.zitiId = data?.id || this.zitiRouterData.id;
        this.nfRouterData.networkId = this.currentNetwork.id;
        if (isEmpty(this.nfRouterData.alternateDomainName)) {
            unset(this.nfRouterData, 'alternateDomainName');
        }
        this.getZitiRouterData();
    }

    showEventsModalFromListPage(zitiRouter: any) {
      this.zitiRouterData = zitiRouter;
      this.getNFHostedEdgeRouter().then((result) => {
        this.nfRouterData = result
        this.showEventsModal();
      });
    }

    showMetricsModalFromListPage(zitiRouter: any) {
      this.zitiRouterData = zitiRouter;
      this.showMetricsModal();
    }

    showEventsModal() {
      this.zitiRouterData.nfId = this.nfRouterData.id;
      this.dialogForm.open(EventsModalComponent, {
        data: {
          resourceType: 'edgeRouter',
          model: this.zitiRouterData,
          networkId: this.currentNetwork.id,
          networkGroupId: this.currentNetwork.networkGroupId,
        },
        height: '620px',
        width: '1050px',
        autoFocus: false,
      });
    }

    showMetricsModal() {
      this.dialogForm.open(MetricsModalComponent, {
        data: {
          resourceType: 'edge-router',
          model: this.zitiRouterData,
          networkGroupId: this.currentNetwork.networkGroupId,
          networkId: this.currentNetwork.id,
        },
        height: '800px',
        width: '1200px',
        autoFocus: false,
      });
    }

    confirmNFHosting() {
        const titleAction = this.nfRouterData?.zitiId ? 'Edit' : 'Setup';
        const data = {
            title: `${titleAction} Hosting`,
            subtitle: 'Use the configuration options below to determine how you would like this router to be hosted.',
            appendId: 'HostRouter',
            icon: 'HostEdgeRouter',
            action: 'Yes',
            showCancelButton: true,
            useInnerHtml: true,
        };
        this.dialogRef = this.dialogForm.open(RouterHostingConfigComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                delay(() => {
                    this.nfHosted = true;
                    this.pollRouterStatus();
                }, 200);
            } else if (!this.nfRouterData.id) {
                this.nfHosted = false;
            }
        });
    }

    async saveNFRouter() {
        if (this.nfRouterData?.id && !this.newRouter) {
            unset(this.nfRouterData, 'enabled');
            unset(this.nfRouterData, 'noTraversal');
            return this.edgeRouterService
                .updateResource(this.nfRouterData)
                .toPromise()
                .then((data: any) => data)
                .catch((error) => {
                    const message = get(error, 'error.errors[0]', 'An unknown error occured');
                    this.growlerService.show(new GrowlerModel('error', 'Error', 'An error occured', message));
                    return false;
                });
        } else {
            if (!this.validateData()) {
                return Promise.resolve();
            }
            const initModel = cloneDeep(this.nfRouterData);
            initModel.zitiId = this.zitiRouterData.id || this.nfRouterData.zitiId;
            initModel.networkId = this.currentNetwork.id;
            return this.edgeRouterService
                .createResource({ body: initModel })
                .toPromise()
                .then((data: any) => {
                    this.hosting = true;
                    this.growlerService.show(
                        new GrowlerModel(
                            'success',
                            'Success',
                            'NetFoundry Hosting Enabled',
                            'Host setup process started'
                        )
                    );
                    this.nfRouterData = data;
                    this.nfRouterData.provider = initModel.provider;
                    this.nfRouterData.alternateDomainName = initModel.alternateDomainName;
                    this.nfRouterData.region = this.nfRouterData.region || initModel.locationCode;
                    this.newRouter = false;
                    return data;
                })
                .catch((error) => {
                    this.nfRouterData.zitiId = undefined;
                    const message = get(error, 'error.errors[0]', 'An unknown error occured');
                    this.growlerService.show(new GrowlerModel('error', 'Error', 'An error occured', message));
                    return false;
                });
        }
    }

    confirmLinkListenerChanged() {
        return new Promise((resolve, reject) => {
            const data = {
                title: 'Restart Required',
                appendId: 'ToggleLinkListener',
                subtitle:
                    '<p>Changing the Link Listener property will cause this router to restart. Any edge connections to the router will be re-established.</p>' +
                    '<p>Do you want to continue?</p>',
                useInnerHtml: true,
                icon: 'RouterHosted',
                action: 'Yes',
                isDestructive: false,
            };
            const dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '280px',
                width: '600px',
                autoFocus: false,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.saveNFRouter();
                }
                resolve(result);
            });
        });
    }

    async validateData(): Promise<any> {
        this.errors = {};
        if (!this.nfHosted || this.nfRouterData.id) {
            return true;
        }
            const validationResults: any = await this.edgeRouterService.validateCreate(this.nfRouterData);

            validationResults.forEach((row: any) => {
                if (row.label === 'zitiId' || row.label === 'networkId') {
                    return;
                }
                if (isEmpty(this.errors[row.label])) {
                    this.errors[row.label] = row.message;
                }
            });
            return isEmpty(this.errors);

    }

    copyRegistration(zitiRouter?: any) {
      this.zitiRouterData = zitiRouter || this.zitiRouterData;
      this.edgeRouterService.getRegistrationToke(this.currentNetwork.id, this.zitiRouterData?.id).toPromise().then((result: any) => {
        navigator.clipboard.writeText(result.registrationKey);
        const growlerData = new GrowlerModel(
          'success',
          'Success',
          `Text Copied`,
          `Registration key copied to clipboard`,
        );
        this.growlerService.show(growlerData);
      });
    }

    hideCopyRegistration(data: any) {
        return data?.isVerified;
    }

    hideReEnroll(data: any) {
      if (!data?.isVerified || data?.hostname?.indexOf('netfoundry.io') >= 0) {
        return true;
      }
      return false;
    }

    reEnrollRouter(zitiRouter?: any) {
      this.zitiRouterData = zitiRouter || this.zitiRouterData;
      const data = {
        title: 'Re-Enroll',
        appendId: 'Router',
        subtitle:
          'Are you sure you want to re-enroll your edge router? This will cause any services associated \
  with it to stop working until it is enrolled again',
        icon: 'Confrim',
        action: 'Yes',
      };
      this.dialogRef = this.dialogForm.open(ConfirmComponent, {
        data: data,
        height: '340px',
        width: '600px',
        autoFocus: false,
      });
      this.dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getNFHostedEdgeRouter().then((result) => {
            this.nfRouterData = result
            this.reissue(this.nfRouterData, 'Re-Enroll');
          });
        }
      });
    }

    reissue(item: EdgeRouterV2, type: string) {
      this.edgeRouterService.resetToken(item.id).subscribe((data: any) => {
          const growlerMessage =
            `${type} process was started successfully <a href="` +
            this.router.createUrlTree(['/process-executions']).toString() +
            '">Click here to find out more</a>';
        this.nfRouterData = {};
        this.zitiRouterData.isVerified = false;
        this.growlerService.show(new GrowlerData('success', 'Success', `${type} Successful`, growlerMessage));
      });
    }

    updateFormData(data: any) {
        this.zitiRouterData = data;
        this.zitiRouterChanged.next(this.zitiRouterData);
    }

    extendAfterViewInits(extentionPoints: any): void {}

    processTableColumns(tableColumns: any): Promise<any> {
        if (this.hiddenColumns) {
          tableColumns = tableColumns.filter((column: any) => {
            return !this.hiddenColumns.includes(column.colId);
          });
        }
        if (this.columnExtensions && this.columnExtensions.length > 0) {
          tableColumns = [...tableColumns, ...this.columnExtensions];
        }
        return tableColumns;
    }

    async getNFHostedEdgeRouter(): Promise<any> {
        if (this.loadingZitiRouterId === this.zitiRouterData.id) {
          return;
        }
        this.loadingZitiRouterId = this.zitiRouterData.id;
        return this.edgeRouterService.getEdgeRouterPage(this.getOptions()).then((result) => result[0]).finally(() => {
          this.loadingZitiRouterId = '';
        });
    }

    getZitiRouterData(): Promise<any> {
      return this.zitiService.getSubdata('edge-routers', this.zitiRouterData.id, '').then(data => {
        this.zitiRouterData = data.data;
        return data;
      });
    }

    pollRouterStatus(attempt = 0, minAttempts = 3) {
        const initModel = cloneDeep(this.nfRouterData);
        this.getNFHostedEdgeRouter().then((result) => {
            if (isEmpty(result)) {
                delay(() => {
                    this.pollRouterStatus(attempt++);
                }, 3000);
                return;
            }

            result.provider = initModel.provider;
            result.alternateDomainName = initModel.alternateDomainName;
            result.region = this.nfRouterData.region || initModel.locationCode;
            this.nfRouterData = result;
            if (this.inProcess || attempt < minAttempts) {
                delay(() => {
                    attempt++;
                    this.pollRouterStatus(attempt);
                }, 3000);
            } else {
                this.hosting = false;
                this.getZitiRouterData();
            }
        });
    }

    get inProcess() {
        return (
            this.nfRouterData?.status === 'PROVISIONING' ||
            this.nfRouterData?.status === 'UPDATING' ||
            this.nfRouterData?.status === 'NEW'
        );
    }

    getOptions() {
        const options: PagedGetOption = {
            params: {
                networkId: this.currentNetwork.id,
                embed: 'host',
                zitiId: this.zitiRouterData.id,
            },
            pageParams: {
                size: 10,
                page: 0,
            },
        };
        return options;
    }

    downloadJWT() {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/ziti-jwt;charset=utf-8,' + encodeURIComponent(this.zitiRouterData?.enrollmentJwt));
        element.setAttribute('download', this.zitiRouterData?.name+".jwt");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    extendOnInit() {

    }
}
