import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { ProviderService, RegionService } from '@netfoundry-ui/shared/apiv2';

import { isEmpty, get, set, unset, debounce, cloneDeep, delay } from 'lodash';
import { ExtensionService, EDGE_ROUTER_EXTENSION_SERVICE } from 'ziti-console-lib';
import { EdgeRouterV2 } from '@netfoundry-ui/shared/model';
import { Router } from '@angular/router';
import { EdgeRoutersExtensionService } from './edge-routers-extension.service';
import {ApiService} from "@netfoundry-ui/shared/services";

@Component({
    selector: 'netfoundry-ui-edge-routers-extension',
    templateUrl: './edge-routers-extension.component.html',
    styleUrls: ['./edge-routers-extension.component.scss'],
})
export class EdgeRoutersExtensionComponent implements OnInit {
    formData: any = {};
    isEditing = false;
    errors: any = {};
    alternateDomainName = '';
    linkListener: any = true;
    wssListener: any = false;
    nfRouterInit = false;
    hosting = false;
    currentNetwork: any = {};
    initNFRouterData = false;
    networkInit = false;
    initBuffer = true;
    loadingRouterData = true;

    constructor(
        @Inject(EDGE_ROUTER_EXTENSION_SERVICE) public svc: EdgeRoutersExtensionService,
        private router: Router,
        private apiService: ApiService
    ) {
      this.networkInit = false;
      this.nfRouterInit = false;
      this.svc.resetNFRouterData();
      delay(() => {
        this.initBuffer = false;
      }, 500);
    }

    ngOnInit() {
        this.svc.zitiRouterData = {};
        this.apiService.currentNetwork.subscribe((network) => {
          this.currentNetwork = network;
          this.networkInit = true;
          if (this.initNFRouterData) {
            this.loadNFRouter();
            this.initNFRouterData = false;
          }
        });
        this.svc.zitiRouterChanged.subscribe((data) => {
            this.formData = data;
            this.isEditing = !isEmpty(this.formData?.id);
            if (this.isEditing) {
                if (!this.currentNetwork?.id) {
                  this.initNFRouterData = true;
                  return;
                }
                this.loadNFRouter();
            } else {
                if (!this.nfRouterInit) {
                    this.svc.resetNFRouterData();
                    this.nfRouterInit = true;
                    this.loadingRouterData = false;
                }
            }
        });
        this.svc.closed.subscribe(() => {
            this.svc.hosting = false;
            this.svc.newRouter = false;
            this.svc.nfHosted = false;
            this.svc.resetNFRouterData();
            this.svc.errors = {};
            this.nfRouterInit = false;
        });
    }

    loadNFRouter() {
      this.svc.resetNFRouterData();
      if (isEmpty(this.svc.zitiRouterData.id)) {
        return;
      }
      this.loadingRouterData = true;
      this.svc.getNFHostedEdgeRouter().then((result) => {
        this.svc.nfRouterData = result || cloneDeep(this.svc.DEFAULT_NF_ROUTER_DATA);
        this.svc.nfHosted = this.svc.nfHosted || !isEmpty(this.svc?.nfRouterData?.zitiId);
        this.nfRouterInit = true;
        if (this.svc.inProcess) {
          this.svc.pollRouterStatus();
        }
      }).finally(() => {
        this.loadingRouterData = false;
      });
    }

    get showProvider() {
      return this.svc?.nfRouterData?.provider !== 'CUSTOMER'
    }
    get focusNfHosting() {
        return this.svc.newRouter && this.svc.nfHosted;
    }

    goToProcesses() {
        this.router.navigate(['/process-executions']);
    }

    editHosting() {
        if (this.disableHostButton) {
            return;
        }
        this.svc.nfHosted = true;
        this.svc.confirmNFHosting();
    }

    get hostAreaToolTip() {
        if (this.disableHostPod) {
            return 'Router must be saved first before setting up hosting';
        } else {
            return '';
        }
    }

    get hostButtonToolTip() {
        if (this.svc.inProcess) {
            return 'Can not edit router hosting while host configuration is updating';
        } else {
            return '';
        }
    }

    get showLoader() {
      return !this.networkInit || !this.nfRouterInit || this.initBuffer || this.loadingRouterData;
    }

    get showHostingConfig() {
        return this.svc?.nfRouterData?.id;
    }

    get disableHostPod() {
        return isEmpty(this.svc?.zitiRouterData?.id) && !this.svc?.newRouter;
    }

    get disableHostButton() {
        return (isEmpty(this.svc?.zitiRouterData?.id) && !this.svc?.newRouter) || this.svc.inProcess;
    }

    get isNFHosted() {
        return !isEmpty(this.svc?.nfRouterData?.zitiId && !this.svc.newRouter) || this.svc.nfHosted;
    }

    set isNFHosted(isHosted) {
        this.svc.nfHosted = isHosted;
    }

    get disableHostForm() {
        return isEmpty(this.svc?.zitiRouterData?.id) && isEmpty(this.svc?.nfRouterData?.zitiId);
    }

    get showUpdating() {
        return this.svc?.inProcess;
    }
}
